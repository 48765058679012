<template>
  <footer>
    <Locale v-if="1 == 0"></Locale>
    <div class="mt-2">

      <hr class="pb-0 mb-0 mt-2" />
      <b-nav tabs justified align="center">
        <b-nav-item href="https://sefos.freshdesk.com" target="_blank">{{ $t("SUPPORT") }}</b-nav-item>
        <b-nav-item v-b-modal.about-modal>{{ $t("ABOUT") }}</b-nav-item>
      </b-nav>

      <b-modal id="about-modal" no-fade hide-header hide-footer centered>
        <div class="text-center">
          <p>
            SEFOS Outlook Addin
          </p>
          <p>Nubibus Software AB &copy; {{ current_year }}</p>
          <p>{{ showVersion }}</p>
        </div>
      </b-modal>
    </div>
  </footer>
</template>
<script>
export default {
  components: {
    Locale: () => import("@/components/Layout/Locale"),
  },
  data() {
    return {};
  },
  computed: {
    current_year() {
      return new Date().getFullYear();
    },
    showVersion() {
      if (process.env.VUE_APP_GIT_COMMIT != "") {
        return "Version: " + process.env.VUE_APP_GIT_COMMIT;
      } else {
        return "4.3";
      }
    },
  },
};
</script>
